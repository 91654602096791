import * as React from 'react';

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import {Flex, Box, Text} from "rebass";

/**
 * Render response error code 302 for unauthenticated pages
 */
class Unauthenticated extends React.Component {
    public render() {
        return (
            <Layout>
                <SEO/>
                <Section>
                    <Flex>
                        <Box>
                            <Headings.h1>{"Unauthenticated"}</Headings.h1>
                            <Text>
                                {"The request could not be completed as a valid authentication token was not passed."}
                            </Text>
                        </Box>
                    </Flex>
                </Section>
            </Layout>
        )
    }
}

export default Unauthenticated;